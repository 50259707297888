<template>
  <div class="container grading">
    <div class="report" v-if="user_exam_id > 0">
      <div class="report-title">
        <div class="title-cont">
          <div class="title">Exam scoring system</div>
          <p class="title-number">No.{{ user_exam_id }}</p>
        </div>
      </div>
      <div class="report-body">
        <div class="report-info">
          <div class="datas-title mini-titles">
            <i class="data-icon result-icon"></i>
            <p class="title-msg">Exam information</p>
          </div>
          <div class="card">
            <el-form label-width="80px">
              <el-form-item>
                <b slot="label">Test</b>
                <h6 style="margin-bottom: 0;line-height:40px">
                  <span class="font-color">
                    {{ exam_info.exam_title }}
                  </span>
                </h6>
              </el-form-item>
              <el-form-item>
                <b slot="label">Student</b>
                <h6 style="margin-bottom: 0;line-height:40px">
                  <b sclass="font-color">
                    {{ exam_info.student.first_name }}
                    {{ exam_info.student.last_name }}
                  </b>
                </h6>
              </el-form-item>
              <el-form-item>
                <b slot="label">Date</b>
                <h6 style="margin-bottom: 0;line-height:40px">
                  <span class="font-color">
                    {{ exam_info.exam_date }}
                  </span>
                </h6>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="report-sections">
          <div class="datas-title mini-titles" v-if="canReport">
            <i class="data-icon result-icon"></i>
            <p class="title-msg">Score and comments</p>
          </div>
          <div class="card">
            <div class="section speaking" v-if="speaking_list.length > 0">
              <speaking
                :score="user_score.speaking_score"
                :list="speaking_list"
              ></speaking>
            </div>
            <div class="section writing" v-if="writing_list.length > 0">
              <writing
                :score="user_score.writing_score"
                :list="writing_list"
              ></writing>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import TOEFL from "@/apis/toefl";
import Speaking from "./sections/Speaking";
import Writing from "./sections/Writing";

export default {
  metaInfo() {
    return {
      title: "Grading - TOEFL Academy"
    };
  },

  components: {
    Speaking,
    Writing
  },

  mixins: [],

  props: [],
  data() {
    return {
      user_evaluation: {},
      user_score: {},
      topic: {},
      exam_info: {},
      canReport: true,
      sections: []
    };
  },
  computed: {
    user_exam_id() {
      return this.$route.query.id;
    },
    reading_list() {
      return _.groupBy(
        _.filter(this.topic, ["skill_name", "reading"]),
        "passage"
      );
    },
    listening_list() {
      return _.groupBy(
        _.filter(this.topic, ["skill_name", "listening"]),
        "passage"
      );
    },
    speaking_list() {
      let speaking_list = _.filter(this.topic, ["skill_name", "speaking"]);
      speaking_list.forEach(element => {
        this.$set(element, "canPlay", true);
        this.$set(element, "width", 0);
        this.$set(element, "progress", 20);
        this.$set(element, "time", 60);
        this.$set(element, "speed", 1);
      });
      return speaking_list;
    },
    writing_list() {
      return _.filter(this.topic, ["skill_name", "writing"]);
    }
  },
  watch: {},

  mounted() {
    this.getTranscript();
  },

  methods: {
    async getTranscript() {
      const res = await TOEFL.getTranscript(this.user_exam_id);
      this.user_evaluation = res.user_evaluation;
      this.user_score = res.user_score;
      this.topic = res.topic;
      this.exam_info = res.exam_info;
    }
  }
};
</script>

<style scoped>
b,
strong {
  font-weight: 500 !important;
}
.card >>> .el-form .el-form-item {
  margin-bottom: 0;
}
.report {
  margin: 60px auto 40px;
  border: 1px solid #ccc;
}
.report-title {
  height: 80px;
  background: var(--themeColor);
  padding: 20px 32px;
}
.title-cont .title {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  float: left;
  margin-right: 12px;
  color: white;
}
.title-cont .title-number {
  opacity: 0.6;
  font-size: 18px;
  line-height: 40px;
  margin-right: 8px;
  float: left;
  font-weight: 600;
}
.mini-titles .title-msg {
  font-size: 16px;
  color: #00448d;
  line-height: 28px;
  float: left;
  font-weight: 700;
  margin-bottom: 0;
}
.datas-title {
  margin: 16px 0;
  overflow: hidden;
}
.report-body {
  padding: 20px 32px;
  background-color: #eeeeee;
}
.card {
  padding: 20px;
}
.section {
  margin: 10px 0;
}
</style>
